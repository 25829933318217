import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import '@fontsource/montserrat/500.css';
import { InterfaceConfiguration } from 'videokube-front-library/dist/types';

const interfaceConfiguration = {
  id: 1,
  status: 'published',
  primary_color: '#001025',
  secondary_color: '#FFFFFF',
  number_of_items_per_columns: 4,
  number_items_in_category_preview: 4,
  number_of_related_videos: 8,
  video_sorting: 'string',
  background_color: "#FFFFFF"
};
//export const getTheme = (interfaceConfigurations:InterfaceConfiguration) => {
export const getTheme = (interfaceConfiguration: InterfaceConfiguration) => {
  return responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: {
          main: interfaceConfiguration.primary_color,
        },
        secondary: {
          main: interfaceConfiguration.secondary_color,
        },
      },
      typography: {
        fontFamily: '"Montserrat", cursive',
      },
      overrides: {
        MuiCard: {
          root: {
            overflow: 'visible',
          },
        },
        MuiButton: {
          root: {
            fontWeight: 700,
            //letterSpacing: '0.15rem',
            borderRadius: '0px',
            padding: '15px 25px',
            '&:hover': {
              backgroundColor: '!important',
            },
          },
        },
        MuiCardActionArea: {
          focusHighlight: {
            backgroundColor: 'white',
          },
        },
        MuiTypography: {
          // Title
          h1: {
            fontSize: '4em',
            textDecoration: 'none',
          },
          // Title specific page
          h2: {
            fontSize: '3em',
            textDecoration: 'none',
          },
          // Title section
          h3: {
            fontSize: '2.6em',
            textDecoration: 'none',
          },
          // Subtitle home
          h4: {
            fontSize: '1.75rem',
            textDecoration: 'none',
          },
          // Title big card
          h5: {
            fontSize: '1.5em',
            lineHeight: '1',
            textDecoration: 'none',
          },
          // Title medium card
          h6: {
            fontSize: '1.3em',
            fontWeight: 'bold',
            color: '#1c4a4e',
            lineHeight: '1',
            textDecoration: 'none',
          },
          body1: {
            fontSize: '1em',
            color: '#555555',
            textDecoration: 'none',
          },
          body2: {
            fontSize: '0.85em',
            color: '#555555',
            textDecoration: 'none',
          },
          // Link
          subtitle1: {
            fontSize: '0.9em',
            color: '#1c4a4e',
            textDecoration: 'none',
          },
          // Bold
          subtitle2: {
            fontSize: '1em',
            color: '#1c4a4e',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            lineHeight: '1',
            textDecoration: 'none',
          },
          // Duration
          overline: {
            fontSize: '0.91em',
            color: '#999999',
            lineHeight: '1',
            textDecoration: 'none',
          },
          // Type
          caption: {
            fontSize: '0.75em',
            opacity: '0.9',
            color: '#999999',
            textDecoration: 'none',
          },
        },
      },
    }),
  );
};
export const DefaultTheme = getTheme(interfaceConfiguration);

export const responsiveHeaderImage = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundColor: "",
    height: "225px",
    [theme.breakpoints.down("sm")]: {
      height: "125px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "325px",
    }
  },
  text: {
    //padding: '100px 0px',
    color: "white",
    textTransform: "uppercase",
    letterSpacing: "0px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "2em",
    }
  },
});

export const FooterStyle = (theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    margin: '0 auto',
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  svg: {
    color: theme.palette.primary.main,
    verticalAlign: "middle",
    fontSize: "2.5rem",
    padding: "0px 10px 0px 10px",
    fill: "white"
  },
  footerText: {
    color: theme.palette.primary.main,
    padding: "25px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    '& a': {
      textDecoration: 'none'
    }
  },
  footerIcon: {
    color: theme.palette.primary.main,
    padding: "25px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  },
  footerButton: {
    justifyContent: 'unset',
    color: theme.palette.primary.main,
    textAlign: 'start',
    marginRight: "35px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px"
    }
  }
});

export const seeAllStyleEndBlock = (theme: Theme) => ({
  seeAll: {
    textTransform: "uppercase",
    letterSpacing: "1px",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    margin: "0px 0px 0px 0px",
  }
});

export const simpleCardNewStyle = (theme: Theme) => ({
  img: {
    borderRadius: "5px",
    objectFit: 'cover',
    objectPosition: 'top',
    position: 'absolute',
    top: '0',
    height: '100%',
  },
  play: {
    position: "absolute",
    color: "white",
    fontSize: "5rem",
    opacity: 0
  },
  root: {
    backgroundColor: theme.palette.secondary.main
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  lazyHolder: {
    width: "100%",
    paddingTop: '56%',
    position: 'relative',
  },
});

export const simpleTextNewStyle = (theme: Theme) => ({
  text: {
    backgroundColor: "#FFFFFF",
    textAlign: 'center',
    padding: '50px 0px 50px 0px',
  }
});
export const PrivacyTextStyle = (theme: Theme) => ({
  text: {
    backgroundColor: "#FFFFFF",
    textAlign: 'justify',
    padding: '25px 16px 25px 16px',
  }
});
export const linkForgetPassword = (theme: Theme) => ({
  linkForgetPassword: {
    color: '#555555',
    paddingTop: '10px',
  }
});
export const navbarStyle = (theme: Theme) => ({
  root: {
    height: '64px',
    '& a': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: 'unset',
    },
    '& div div': {
      height: '100%',
      [theme.breakpoints.up("sm")]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }
    },
    [theme.breakpoints.down("sm")]: {
      height: '56px',
    }
  },
  toolbar: {
    height: '100%',
  },
  logo: {
    width: 220,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 120
    }
  },
  button: {
    color: theme.palette.primary.main,
    padding: '0px',
    '&:hover': {
      color: "#D9272E"
    }
  },
  buttonRight: {
    padding: '8px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: "#D9272E",
    }
  },
  link: {
    color: theme.palette.primary.main,
    zIndex: "1400",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px"
    },
    '&:hover': {
      color: "#D9272E"
    }
  },
  activeButton: {
    color: "#D9272E"
  },
  dropdown: {
    zIndex: "1800 ! important",
    marginTop: "80px",
    maxHeight: 'calc(100%)',
    '& .MuiPaper-root': {
      top: '-16px !important',
      backgroundColor: "#D9272E"
    }
  },
  swiper: {
    '& .MuiPaper-root': {
      color: theme.palette.primary.main
    }
  },
})

export const translationComponent = (theme: Theme) => ({
  dropdown: {
    marginTop: "80px",
    maxHeight: 'calc(100%)',
    '& .MuiPaper-root': {
      top: '-16px !important',
      backgroundColor: "#D9272E",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "61px",
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.main,
      },
    }
  },
  link: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0),
    zIndex: "1800",
    marginRight: "8px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px"
    },
    '&:hover': {
      color: "#D9272E"
    }
  },
  activeButton: {
    color: "#D9272E"
  },
  button: {
    color: '#FFFFFF',
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  },
})
